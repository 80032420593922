import React, { FC } from 'react'
import styled from 'styled-components'
import { ImRss2 } from 'react-icons/im'
import { FaMastodon, FaDiaspora } from 'react-icons/fa'

const StySocialIconsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  font-size: 1rem;
  width: 20rem;
  justify-content: space-between;
`

const StySocialIcon = styled.div`
  display: flex;
  align-items: center;
`

const StySocialIconText = styled.div`
  margin-left: 0.5rem;
`

const SocialMediaRow: FC<{}> = () => (
  <StySocialIconsContainer>
    <a href="../rss.xml" target="new" style={{ color: 'rgb(245, 146, 54)' }}>
      <StySocialIcon>
        <ImRss2 color="rgb(245, 146, 54)" size="20" />
        <StySocialIconText>RSS</StySocialIconText>
      </StySocialIcon>
    </a>
    <a href="https://muenchen.social/@puls" target="new" style={{ color: 'rgb(97, 146, 224)' }}>
      <StySocialIcon>
        <FaMastodon color="rgb(97, 146, 224)" size="20" />
        <StySocialIconText>Mastodon</StySocialIconText>
      </StySocialIcon>
    </a>
    <a href="https://pod.asap-soft.com/people/3f1d6af7e86de6cf" target="new" style={{ color: 'rgb(255, 255, 255)' }}>
      <StySocialIcon>
        <FaDiaspora color="rgb(255, 255, 255)" size="20" />
        <StySocialIconText>Diaspora</StySocialIconText>
      </StySocialIcon>
    </a>
  </StySocialIconsContainer>
)

export default SocialMediaRow
